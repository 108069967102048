<template>
  <div style="background-color: rgb(240, 242, 245); height: 100vh">
    <home-topbar
      class="topbar"
      :is-home="false"
      :is-personal="false"
      :is-notification="false"
      :is-worksapce="true"
      @dialoghover="false"
    ></home-topbar>
    <div class="contain">
      <div class="imgBigBox" :style="elementStyle">
        <div class="imgBox">
          <div class="brand-box">
            <img src="../../assets/img/newWorkSpace/brand.png" alt="" />
            <div>
              <img
                ref="showCarouselIcon"
                src="../../assets/showCarousel.png"
                style="width: 50px; height: 50px"
                class="showCarouselIcon"
                @click="openMyCarousel"
              />
            </div>
          </div>
          <div class="car-box inner">
            <img src="../../assets/img/newWorkSpace/car.png" alt="" />
          </div>
          <div
            style="display: flex; position: absolute; left: 100px; top: 600px"
          >
            <div id="userGuide" class="userGuide" @click="goToQuestion">
              <i
                class="iconfont icon-workbench_manual"
                style="font-size: 52px"
              ></i>
            </div>
            <div id="officialWeb" class="officialWeb" @click="goToofficialWeb">
              <i
                class="iconfont icon-workbench_website"
                style="font-size: 52px"
              ></i>
            </div>
            <el-popover placement="top" width="350">
              <div class="weChatBox">
                <img
                  style="max-width: 350px; max-height: 350px"
                  src="../../assets/gongzhonghao.jpg"
                  alt=""
                />
                <div>{{ $t("workSpace.WeChatContent") }}</div>
              </div>
              <div id="officialWeb" slot="reference" class="officialWeb">
                <i
                  class="iconfont icon-workbench_wechat"
                  style="font-size: 52px"
                ></i>
              </div>
            </el-popover>

            <div id="officialWeb" class="officialWeb" @click="goToB">
              <i
                class="iconfont icon-workbench_bilibili"
                style="font-size: 52px"
              ></i>
            </div>
            <div id="officialWeb" class="officialWeb" @click="goToRecommend">
              <i
                class="iconfont icon-workbench_bonus"
                style="font-size: 52px"
              ></i>
            </div>
            <div id="officialWeb" class="officialWeb" @click="goToYindao">
              <i
                class="iconfont icon-workbench_guide1"
                style="font-size: 52px"
              ></i>
            </div>
            <div
              id="officialWeb"
              ref="updateShow"
              class="officialWeb"
              @click="updateShow"
            >
              <i
                class="iconfont icon-workbench_upgrade"
                style="font-size: 52px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="btnBigBox" :style="elementStyle_tight">
        <div class="btnBox">
          <div class="project" @click="goToProject">
            <div class="backIconBox">
              <i class="iconfont icon-a-file-chart1 backIcon"></i>
            </div>
            <div class="btn-text">
              <div class="dataIconBox">
                <i class="iconfont icon-a-file-chart1 dataIcon"></i>
              </div>
              {{ $t("home.projectDataSummary") }}
            </div>
          </div>
          <div class="individual" @click="goToIndividiual">
            <div class="backIconBox">
              <i class="iconfont icon-a-Simba_Fill_MyData1 backIcon"></i>
            </div>
            <div class="btn-text">
              <div class="dataIconBox">
                <i class="iconfont icon-a-Simba_Fill_MyData1 dataIcon"></i>
              </div>
              {{ $t("home.personalDataSummary") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <basicLicenseExpireDialog
      :dialog-visible.sync="dialogVisible"
      :title_text="$t('workSpace.UpcomingExpirationAlert')"
      :basic_license_time="basic_license_time"
    ></basicLicenseExpireDialog>

    <div v-show="showCarousel || showUpdate" id="mask"></div>

    <div v-if="showUpdate" class="my-update">
      <!-- <el-carousel-item v-for="(item, index) in tips" :key="index"> -->
      <div class="itemBox">
        <i
          class="iconfont icon-off_right welcomeClose"
          style="cursor: pointer; color: white"
          @click="closeUpdate"
        ></i>
        <div class="welcomeImg">
          <div style="display: flex; align-items: center">
            <div
              style="
                font-size: 48px;
                color: white;
                font-weight: 700;
                margin-left: 70px;
              "
            >
              {{ $t("home.update_1") }}
            </div>
            <div
              style="
                color: #004caa;
                border: 1px solid white;
                border-radius: 5px;
                background-color: white;
                font-size: 22px;
                font-weight: 700;
                padding: 2.5px 20px;
                display: flex;
                align-items: center;
                margin-left: 50px;
              "
            >
              V {{ up_version }}
            </div>
          </div>
          <!-- <img :src="update_img" alt="" /> -->
        </div>

        <div class="welcomeTopBack">
          <h1 class="welcomeMedium">{{ $t("home.update_3") }}</h1>
          <div style="max-height: 30vh; overflow: auto">
            <div
              v-for="item in update_list[$t('tara.tara_lang')]"
              :key="item"
              class="welcomeContent"
            >
              <div style="color: #004caa; font-size: 16px; margin-right: 20px">
                ●
              </div>
              {{ item }}
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: flex-end; margin-right: 25px"
        >
          <div
            style="
              color: white;
              font-size: 20px;
              background: #004caa;
              padding: 5px 20px;
              border-radius: 5px;
              margin: 50px 0 30px 0;
              cursor: pointer;
            "
            @click="gotoupdate"
          >
            {{ $t("home.update_2") }}
          </div>
        </div>
      </div>
    </div>
    <!-- <transition name="minimize"> -->
    <transition
      name="mycarousel"
      leave-active-class="animate__animated animate__bounceOut animate__faster"
      enter-active-class="animate__animated animate__fadeIn animate__2s"
    >
      <div v-if="showCarousel" ref="my-carousel" class="my-carousel">
        <el-carousel
          :interval="30000"
          :autoplay="true"
          height="655px"
          @change="handleCarouselChange"
        >
          <el-carousel-item v-for="(item, index) in tips" :key="index">
            <div v-if="index == 0" class="itemBox">
              <i
                class="iconfont icon-off_right welcomeClose"
                style="cursor: pointer"
                @click="closeMyCarousel"
              ></i>
              <img class="welcomeImg" :src="item.img" alt="" />

              <div class="welcomeTopBack">
                <h1 class="welcomeMedium">{{ item.title }}</h1>
                <div class="welcomeContent">{{ item.content }}</div>
              </div>
            </div>
            <div v-else class="itemBox">
              <div class="topBack">
                <h1 class="medium">
                  {{ item.title }}
                  <i
                    class="iconfont icon-off_right"
                    style="cursor: pointer"
                    @click="closeMyCarousel"
                  ></i>
                </h1>
                <div class="content">{{ item.content }}</div>
              </div>

              <img class="carouselImg" :src="item.img" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-checkbox
          v-show="noMoreVisible"
          v-model="checked"
          class="noMoreShow"
          @change="checkNoMoreShow"
          >{{ $t("btn.noLongerDisplayed") }}</el-checkbox
        >
      </div>
    </transition>

    <!-- </transition> -->
    <el-dialog
      :visible.sync="updateDialog"
      :show-close="false"
      :fullscreen="true"
      custom-class="custom_update_class"
    >
      <div class="loginBox">
        <div class="login">
          <div class="login-imag">
            <div class="loginLogo">
              <img
                class="loginLogo-1"
                src="../../assets/img/newLogin/newLoginName.png"
                alt=""
              />
              <div class="shadowBox">
                <img
                  class="loginLogo-2"
                  src="../../assets/img/newLogin/newLoginCar1.png"
                  alt=""
                />
              </div>
            </div>
            <div class="logo-right">
              <img :src="logo_light" />
            </div>
          </div>
        </div>
        <div class="text-right-area">
          <div class="text-right-top">
            <i class="el-icon-close" @click="CloseupdateDialog"></i>
          </div>
          <div class="text-right-middle">
            <p>v2.0.27 {{ $t("workSpace.update.newVersion") }}</p>
            <p>{{ $t("workSpace.update.newVersionDes") }}</p>
            <span @click="showDetail">{{
              $t("workSpace.update.seeIntroduction")
            }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="updateDetailDialog"
      :show-close="false"
      :fullscreen="true"
      custom-class="custom_update_class"
    >
      <div class="detail-wrap">
        <div class="detail-icon-wrap">
          <div class="text-right-top">
            <i class="el-icon-close" @click="closeUpdateDetailDialog"></i>
          </div>
        </div>
        <el-carousel
          :interval="8000"
          ref="carousel"
          :autoplay="false"
          arrow="always"
          style="width: 90%; margin: 0 auto; height: 670px"
          @change="updateCarouselChange"
        >
          <el-carousel-item
            v-for="item in updatePicList"
            :key="item.pic"
            style="height: 648px"
          >
            <img
              :src="item.pic"
              style="
                width: 1300px;
                height: 636px;
                box-shadow: 5px 5px 5px 2px #888;
              "
            />
          </el-carousel-item>
        </el-carousel>
        <div class="pic-des">
          <p style="font-size: 24px; font-weight: bold">
            {{ desList[updateCarouselIndex].title }}
            <span>
              <el-button
                type="primary"
                size="medium"
                style="float: right; line-height: 1.3"
                @click="shareQrcode(updateCarouselIndex)"
                >{{ $t("workSpace.update.share") }}</el-button
              >
            </span>
          </p>
          <p style="font-size: 18px">{{ desList[updateCarouselIndex].des }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="qrcodeShow"
      :show-close="true"
      width="30%"
      custom-class="custom_update_class"
    >
      <qr-code
        v-if="qrcodeShow"
        ref="qrCode"
        :text="qrUrl"
        :swidth="3.33"
      ></qr-code>
    </el-dialog>
  </div>
</template>

<script>
import HomeTopbar from "@/components/homeTopbar";
import qrCode from "@/components/qrcode";
import { get_basic_license } from "@/network/license/index.js";
import basicLicenseExpireDialog from "./components/basicLicenseExpireDialog.vue";
import logo_light from "@/assets/logo_light.png";
import config from "@/../config/index";
import { getPhoneInfo } from "@/network/login";
// 局部导入animate.css
import "animate.css";

import { mapGetters } from "vuex";
export default {
  components: { HomeTopbar, basicLicenseExpireDialog, qrCode },
  data() {
    return {
      showUpdate: false,
      update_img: require("../../assets/update.png"),
      up_version: "2.0.27",
      update_list: {
        ch: [
          "新增AI生成架构图的功能，支持国产大模型智谱AI接入，更加安全可控",
          "新增W模型追溯视图功能，更好支持ASPICE4.0 及ISO 26262追溯场景",
          "新增文件类型级别的操作权限设置，支持企业内部更精细的权限管控",
          "工作台新增项目数据和个人数据汇总，更好支持多项目并行的项目管理",
          "TARA功能支持用户自主添加攻击路径库和风险处置库，企业更加容易执行ISO 21434风险资产分析",
          "新增ASPICE项目模板功能，一键创建ASPICE项目设置及海量规范文档模板",
          "MappingSpace思维导图支持插入飞书、ones等知识库，更好与其他项目管理工具协同",
          "进一步丰富文件和知识库模版库",
          "优化HARA功能的交互逻辑",
          "优化软件英文环境下部分英文翻译",
          "优化产品中部分提示消息的展示",
        ],
        en: [
          "Add the function of generating architecture diagrams for AI, supporting the integration of domestic large model intelligent spectrum AI, making it more secure and controllable.",
          "New W model traceability view function added, better supporting ASPICE4.0 and ISO 26262 traceability scenarios",
          "Add file type level operation permission settings to support more refined permission control within the enterprise.",
          "The workbench has added project data and personal data summary to better support parallel project management for multiple projects.",
          "The TARA function supports users to independently add attack path libraries and risk disposal libraries, making it easier for enterprises to perform ISO 21434 risk asset analysis.",
          "Added ASPICE project template function, one click creation of ASPICE project settings and massive specification document templates",
          "Mapping Space mind maps support inserting knowledge bases such as Feishu and Ones, making it easier to collaborate with other project management tools.",
          "Further enrich the file and knowledge base template library",
          "Optimize the interaction logic of HARA functionality",
          "Optimize partial English translation in software English environment",
          "Optimize the display of some prompt messages in the product",
        ],
      },
      windowWidth: 0,
      windowHeight: 0,
      dialogVisible: false,
      basic_license_time: 0,
      elementStyle: {
        position: "absolute",
        top: "25.5px",
        "margin-top": "35px",
      },
      elementStyle_tight: {
        position: "absolute",
        top: "25.5px",
        left: "775px",
        "margin-top": "35px",
      },
      timer: null,
      showCarousel: false,
      tips: [
        {
          title:
            this.$t("workSpace.welComeTitle1") +
            JSON.parse(localStorage.getItem("store")).user.name +
            this.$t("workSpace.welComeTitle2"),
          content: this.$t("workSpace.welComeContent"),
          img: require("../../assets/welcome.png"),
        },
        {
          title: this.$t("workSpace.fun1Title"),
          content: this.$t("workSpace.fun1Content"),
          img: this.$t("workSpace.fun1Gif"),
        },

        {
          title: this.$t("workSpace.fun2Title"),
          content: this.$t("workSpace.fun2Content"),
          img: this.$t("workSpace.fun2Gif"),
        },
        {
          title: this.$t("workSpace.fun3Title"),
          content: this.$t("workSpace.fun3Content"),
          img: this.$t("workSpace.fun3Gif"),
        },
        {
          title: this.$t("workSpace.fun4Title"),
          content: this.$t("workSpace.fun4Content"),
          img: this.$t("workSpace.fun4Gif"),
        },
        {
          title: this.$t("workSpace.fun5Title"),
          content: this.$t("workSpace.fun5Content"),
          img: this.$t("workSpace.fun5Gif"),
        },
        {
          title: this.$t("workSpace.fun6Title"),
          content: this.$t("workSpace.fun6Content"),
          img: this.$t("workSpace.fun6Gif"),
        },
        {
          title: this.$t("workSpace.fun7Title"),
          content: this.$t("workSpace.fun7Content"),
          img: this.$t("workSpace.fun7Gif"),
        },
        {
          title: this.$t("workSpace.fun8Title"),
          content: this.$t("workSpace.fun8Content"),
          img: this.$t("workSpace.fun8Gif"),
        },
        {
          title: this.$t("workSpace.fun9Title"),
          content: this.$t("workSpace.fun9Content"),
          img: this.$t("workSpace.fun9Gif"),
        },
        {
          title: this.$t("workSpace.fun10Title"),
          content: this.$t("workSpace.fun10Content"),
          img: this.$t("workSpace.fun10Gif"),
        },
        {
          title: this.$t("workSpace.fun11Title"),
          content: this.$t("workSpace.fun11Content"),
          img: this.$t("workSpace.fun11Gif"),
        },
      ],
      checked: false,
      noMoreVisible: true,
      recordCarousel: [0],
      updateDialog: false,
      updateDetailDialog: false,
      qrcodeShow: false,
      logo_light: logo_light,
      updateCarouselIndex: 0,
      qrUrl: "",
      updatePicList: [
        {
          pic: this.$t("workSpace.update.pic1"),
        },
        {
          pic: this.$t("workSpace.update.pic2"),
        },
        {
          pic: this.$t("workSpace.update.pic3"),
        },
        {
          pic: this.$t("workSpace.update.pic4"),
        },
        {
          pic: this.$t("workSpace.update.pic5"),
        },
        {
          pic: this.$t("workSpace.update.pic6"),
        },
        {
          pic: this.$t("workSpace.update.pic7"),
        },
      ],
      desList: [
        {
          title: this.$t("workSpace.update.title1"),
          des: this.$t("workSpace.update.des1"),
        },
        {
          title: this.$t("workSpace.update.title2"),
          des: this.$t("workSpace.update.des2"),
        },
        {
          title: this.$t("workSpace.update.title3"),
          des: this.$t("workSpace.update.des3"),
        },
        {
          title: this.$t("workSpace.update.title4"),
          des: this.$t("workSpace.update.des4"),
        },
        {
          title: this.$t("workSpace.update.title5"),
          des: this.$t("workSpace.update.des5"),
        },
        {
          title: this.$t("workSpace.update.title6"),
          des: this.$t("workSpace.update.des6"),
        },
        {
          title: this.$t("workSpace.update.title7"),
          des: this.$t("workSpace.update.des7"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["theme"]),
    elementStyle_computed() {
      // 基于窗口大小和设想的元素尺寸计算位置
      const top = this.windowHeight / 2 - 330 + "px";
      return {
        position: "absolute",
        top: top,
        // 其他样式...
      };
    },
    elementStyle_tight_computed() {
      // 基于窗口大小和设想的元素尺寸计算位置
      const top = this.windowHeight / 2 - 330 + "px";
      const left = this.windowWidth / 2 + "px";
      return {
        position: "absolute",
        top: top,
        left: left,
      };
    },
  },
  watch: {
    elementStyle_computed(newVal, oldVal) {
      if (parseInt(newVal.top, 10) > 60) {
        this.elementStyle = newVal;
      } else if (parseInt(newVal.top, 10) < 60) {
        this.elementStyle = {
          position: "absolute",
          top: newVal.top,
          "margin-top": 60 - parseInt(newVal.top, 10) + "px",
        };
      }
    },
    elementStyle_tight_computed(newVal, oldVal) {
      if (parseInt(newVal.top, 10) > 60) {
        this.elementStyle_tight = newVal;
      } else if (parseInt(newVal.top, 10) < 60) {
        this.elementStyle_tight = {
          position: "absolute",
          top: newVal.top,
          left: newVal.left,
          "margin-top": 60 - parseInt(newVal.top, 10) + "px",
        };
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getPhoneInfos();
    if (localStorage.getItem("updateVersion") != this.up_version) {
      this.updateDialog = true;
      localStorage.setItem("updateVersion", this.up_version);
    }
    this.handleResize(); // 初次加载时调整元素位置
    if (localStorage.getItem("carouselNoMoreShow") === "true") {
      this.showCarousel = false;
      const showCarouselIcon = this.$refs["showCarouselIcon"];
      setTimeout(() => {
        showCarouselIcon.classList.add("roate");
      }, 700);
    } else {
      this.showCarousel = true;
    }
    window.addEventListener("resize", this.handleResize);
    get_basic_license().then((res) => {
      let basic_license_time = res.dueDateTime;
      if (basic_license_time) {
        this.basic_license_time = parseInt(
          (new Date(basic_license_time).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        );
        // 基础软件一个星期到期提醒
        if (
          this.basic_license_time < 6 &&
          this.$store.getters.roles[0].name == "SYSTEM_MANAGER"
        ) {
          this.dialogVisible = true;
        }
      } else {
        // 免费版不做处理
      }
    });
  },

  methods: {
    getPhoneInfos() {
      getPhoneInfo().then((res) => {
        if (!res.contactPhone) {
          // this.$router.push("/choseLargelanguage");
        }
      });
    },
    shareQrcode(index) {
      this.qrUrl = `https://ms.ytdevops.com/qrPoster?index=${index}`;
      this.$nextTick(() => {
        this.qrcodeShow = true;
      });
    },
    changePic(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    goleft() {
      this.$refs.abbreviationList.scrollLeft = 0;
    },
    goright() {
      this.$refs.abbreviationList.scrollLeft = 999;
    },
    CloseupdateDialog() {
      this.updateDialog = false;
      const updateShow = this.$refs["updateShow"];
      updateShow.classList.remove("roate");
      setTimeout(() => {
        updateShow.classList.add("roate");
      }, 700);
    },
    closeUpdateDetailDialog() {
      this.updateDetailDialog = false;
      const updateShow = this.$refs["updateShow"];
      updateShow.classList.remove("roate");
      setTimeout(() => {
        updateShow.classList.add("roate");
      }, 700);
    },
    // 走马灯change
    updateCarouselChange(index) {
      this.updateCarouselIndex = index;
    },
    // 版本更新
    showDetail() {
      this.updateDialog = false;
      this.updateDetailDialog = true;
    },
    goToIndividiual() {
      this.$router.push({ path: `/home/personalDataSummary` });
    },
    goToProject() {
      this.$router.push({ path: `/home/projectDataSummary` });
    },
    goToQuestion() {
      window.open(this.$t("UserGuide"), "_blank");
    },
    goToofficialWeb() {
      window.open("https://www.ytdevops.com", "_blank");
    },
    goToB() {
      window.open(
        "https://space.bilibili.com/520603057?spm_id_from=333.1007.0.0",
        "_blank"
      );
    },
    goToYindao() {
      this.$router.push({
        path:
          this.$store.getters.t_joined_projects[0].projectId + `/home/guide`,
      });
    },
    goToRecommend() {
      window.open(
        " https://ms.ytdevops.com/pubKnowledge/accffe88-d8ef-458d-80e6-5ea2ab55de07"
      );
    },
    updateShow() {
      this.updateDialog = true;
      const updateShow = this.$refs["updateShow"];
      updateShow.classList.remove("roate");
    },
    handleResize() {
      // 当窗口大小改变时更新数据
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    debounceHandleResize(fn, delay) {
      let that = this;
      return function () {
        if (that.timer != null) {
          clearTimeout(that.timer);
          that.timer = null;
        }
        that.timer = setTimeout(function () {
          fn.apply(that);
        }, delay);
      };
    },
    openMyCarousel() {
      this.showCarousel = true;
      this.noMoreVisible = true;
      const showCarouselIcon = this.$refs["showCarouselIcon"];
      showCarouselIcon.classList.remove("roate");
      this.checked =
        localStorage.getItem("carouselNoMoreShow") === "true" ? true : false;
    },
    closeUpdate() {
      this.showUpdate = false;
    },
    closeMyCarousel() {
      this.showCarousel = false;
      const showCarouselIcon = this.$refs["showCarouselIcon"];
      showCarouselIcon.classList.remove("roate");
      setTimeout(() => {
        showCarouselIcon.classList.add("roate");
      }, 700);
    },
    checkNoMoreShow(value) {
      localStorage.setItem("carouselNoMoreShow", value);
    },
    gotoupdate() {
      window.open(
        "https://ms.ytdevops.com/pubKnowledge/0b7912d6-38ef-478a-a381-f0eaefe8289c"
      );
    },
    handleCarouselChange(value) {
      const len = this.tips.length;
      if (value == len - 1 || value == 0 || value == 1) {
        this.noMoreVisible = true;
      } else {
        this.noMoreVisible = false;
      }

      // if(!this.recordCarousel.includes(value)){
      //     this.recordCarousel.push(value)
      //     if(this.recordCarousel.length == len){
      //         this.$message.success({
      //             type: "success",
      //             message: '恭喜你成为最佳新人！',
      //         })
      //     }else if(value == len-1){
      //         console.log(111);
      //         this.$message.warning({
      //             type: "warning",
      //             message: '还有些轮播图没看完！',
      //         })
      //     }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  height: 60px;
  background-color: rgba(48, 100, 143, 1);
  width: 100vw;
  padding: 0;
}
.contain {
  display: flex;
  width: 100vw;
  .imgBigBox {
    width: 50vw;
    height: 100%;
    .imgBox {
      position: relative;
      padding-top: 60px;
      margin: auto;
      width: 1200px;
      height: 90vh;
      background: url("../../assets/img/newWorkSpace/background.png");
      background-repeat: no-repeat;
      background-position: 201px 125px;
      background-size: 800px 469px;
      .brand-box {
        position: absolute;
        top: 58px;
        left: 148px;
        margin: 0;
        padding: 0;

        img {
          width: 652px;
          height: 260px;
        }
      }

      .car-box {
        position: absolute;
        top: 180px;
        left: 217px;
        margin: 0;
        padding: 0;

        img {
          width: 865px;
          height: 487px;
        }
      }

      .userGuide {
        cursor: pointer;
        color: rgba(24, 79, 169, 1);
        text-align: center;
      }
      .officialWeb {
        cursor: pointer;
        color: rgba(24, 79, 169, 1);
        text-align: center;
        margin-left: 30px;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .btnBigBox {
    z-index: 1;
    width: 50vw;
    height: 100%;
    .btnBox {
      // position: relative;
      margin: 0 auto;
      width: 300px;
      padding-top: 60px;
      .project {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: 497px;
        height: 265px;
        text-align: center;
        opacity: 1;
        border-radius: 30px;
        background: rgba(234, 241, 249, 1);
        box-shadow: inset 2px 2px 4px 2px rgba(255, 255, 255, 0.3),
          2px 2px 4px 5px rgba(1, 77, 170, 0.2);
        .backIconBox {
          z-index: 0;
          position: absolute;
          bottom: 2px;
          right: 28px;
          width: 200px;
          height: 240px;
          .backIcon {
            font-size: 234px;
            color: rgb(153, 192, 231, 0.28);
          }
        }
      }
      .individual {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: 497px;
        height: 265px;
        text-align: center;
        margin-top: 30px;
        opacity: 1;
        border-radius: 30px;
        background: rgba(234, 241, 249, 1);
        box-shadow: inset 2px 2px 4px 2px rgba(255, 255, 255, 0.3),
          2px 2px 4px 5px rgba(1, 77, 170, 0.2);
        .backIconBox {
          position: absolute;
          bottom: 4px;
          right: 56px;
          width: 200px;
          height: 240px;
          .backIcon {
            font-size: 234px;
            color: rgb(153, 192, 231, 0.28);
          }
        }
      }
      .btn-text {
        z-index: 1;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        .dataIconBox {
          width: 90px;
          height: 90px;
          line-height: 90px;
          border-radius: 50%;
          background-color: rgba(6, 64, 166, 1);
          margin-right: 30px;
          .dataIcon {
            color: white;
            font-size: 50px;
          }
        }
      }
    }
  }
  .inner {
    animation: innerAnimation 1s;
  }
}
@keyframes innerAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
// @media screen and (min-width: 1940px){
//     .brand-box {
//         img {
//             width: 760px !important;
//             height: 310px !important;
//         }
//     }

//     .car-box {
//         img {
//             width: 1140px !important;
//             height: 888px !important;
//         }
//     }
//     .userGuide{
//         top: 715px !important;
//     }
// }

#mask {
  width: 100%;
  height: calc(100% - 60px);
  opacity: 0.6;
  background-color: black;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 998;
}
.my-update {
  border-radius: 30px;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  margin-left: calc(-1050px / 2);
  margin-top: calc(-635px / 2);
  width: 1050px;
  // transition: transform 1s ease-in-out, opacity 1s;
  .itemBox {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  .topBack {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    height: 126px;
    background-image: url("../../assets/lunboTop.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .medium {
    margin: 0;
    margin-bottom: 5px;
    position: relative;
    height: 30px;
    text-align: center;
    align-content: center;
    color: white;
    .icon-off_right {
      position: absolute;
      right: 20px;
    }
  }
  .content {
    max-width: 900px;
    align-self: center;
    font-size: 18px;
    padding: 10px 0px;
    height: 45px;
    color: white;
  }
  .carouselImg {
    max-height: 448px;
    align-self: center;
    margin-top: -40px;
    margin-bottom: 30px;
    width: 100%;
  }
  .noMoreShow {
    position: absolute;
    bottom: 18px;
    right: 35px;
    color: #716666;
    z-index: 1000;
  }
  .welcomeImg {
    background: url("../../assets/update.png");
    background-size: 100% 100%;
    height: 250px;
    align-self: center;
    margin-top: -50px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .welcomeClose {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .welcomeTopBack {
    .welcomeMedium {
      margin: 0;

      margin-left: 70px;
      margin-bottom: 25px;
      position: relative;
      height: 30px;
      text-align: start;
      align-content: center;
      font-size: 24px;
      color: #303133;
    }
    .welcomeContent {
      display: flex;
      margin-left: 70px;
      margin-bottom: 10px;

      max-width: 900px;
      align-self: center;
      font-size: 20px;
      line-height: 45px;
      text-align: start;
      align-items: flex-start;
    }
  }
}
.my-carousel {
  border-radius: 30px;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  margin-left: calc(-1050px / 2);
  margin-top: calc(-635px / 2);
  width: 1050px;
  // transition: transform 1s ease-in-out, opacity 1s;
  .itemBox {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  .topBack {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    height: 126px;
    background-image: url("../../assets/lunboTop.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .medium {
    margin: 0;
    margin-bottom: 5px;
    position: relative;
    height: 30px;
    text-align: center;
    align-content: center;
    color: white;
    .icon-off_right {
      position: absolute;
      right: 20px;
    }
  }
  .content {
    max-width: 900px;
    align-self: center;
    font-size: 18px;
    padding: 10px 0px;
    height: 45px;
    color: white;
  }
  .carouselImg {
    max-width: 900px;
    max-height: 448px;
    align-self: center;
    margin-top: 35px;
    margin-bottom: 30px;
  }
  .noMoreShow {
    position: absolute;
    bottom: 18px;
    right: 35px;
    color: #716666;
    z-index: 1000;
  }
  .welcomeImg {
    max-width: 900px;
    max-height: 330px;
    align-self: center;
    margin-top: 105px;
    margin-bottom: 50px;
  }
  .welcomeClose {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .welcomeTopBack {
    display: flex;
    flex-direction: column;

    .welcomeMedium {
      margin: 0;
      margin-bottom: 5px;
      position: relative;
      height: 30px;
      text-align: center;
      align-content: center;
      font-size: 28px;
    }
    .welcomeContent {
      max-width: 900px;
      align-self: center;
      font-size: 20px;
      padding: 10px 0px;
      height: 45px;
    }
  }
}
.showCarouselIcon {
  position: absolute;
  cursor: pointer;
  top: -2px;
  left: 648px;
  color: #0640a6;
  z-index: 990;
}
::v-deep .el-carousel__mask {
  background-color: transparent;
}
::v-deep .el-carousel__button {
  background-color: #a19999;
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: rgba(6, 64, 166, 1);
}
::v-deep .el-carousel {
  border-radius: 30px;
}
.mycarousel-enter-from {
  transform: scale(4);
}
.roate {
  animation: roateIcon 0.7s ease-in 2 alternate;
  // animation-iteration-count: ;
}
// @keyframes roateIcon {
//     25%{
//         transform: translateY(-20px) rotate(-60deg);
//     }
//     50%{
//         transform: translateY(0px) rotate(0deg);
//     }
//     75%{
//         transform: translateY(-20px) rotate(60deg);
//     }
//     100%{
//         transform: translateY(0) rotate(0deg);
//     }
// }
@keyframes roateIcon {
  0% {
    transform: translateY(0) scale(1.2, 0.8);
  }
  15% {
    transform: scale(0.8, 1.2);
  }
  50% {
    transform: translateY(-50px) scale(1, 1);
  }
  85% {
    transform: scale(0.8, 1.2);
  }
  100% {
    transform: translateY(0) scale(1.2, 0.8);
  }
}
.weChatBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    text-align: center;
    padding: 0 13px 0 13px;
  }
}
.loginBox {
  display: flex;
  .text-right-area {
    width: 50%;
    background-color: #edf6fb;
    .text-right-top {
      padding: 30px 30px 0 0;
      text-align: right;
      i {
        color: #000;
        margin-right: 10px;
        cursor: pointer;
        &:first-of-type {
          font-size: 18px;
          vertical-align: 3px;
        }
        &:last-of-type {
          font-size: 28px;
        }
      }
    }
    .text-right-middle {
      padding-top: 200px;
      & > p {
        color: #000;
        font-weight: bold;
        &:first-of-type {
          font-size: 60px;
          margin-bottom: 24px;
        }
        &:nth-of-type(2) {
          font-size: 20px;
        }
      }
      & > span {
        display: inline-block;
        color: #fff;
        width: fit-content;
        padding: 8px 14px;
        border-radius: 4px;
        margin-top: 20px;
        background-color: #336ced;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
.language-option {
  position: absolute;
  top: 30px;
  transform: translateX(30px);
}
.logo_origin {
  height: 10px;
  font-size: 8px;
  font-weight: 400;
  float: left;
  bottom: 10px;
  right: 20px;
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
}
.login {
  overflow: hidden;
  width: 50vw;
  height: 100vh;
  max-width: 50vw;
  min-width: 50vh;
  box-sizing: border-box;
  .login-imag {
    width: 100%;
    height: 100%;
    // background-image: url('../../assets/img/newLogin/newLogin.jpg');
    background-image: url("../../assets/img/newLogin/bg.png");

    background-size: 50vw 100vh;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .logo-right {
      position: absolute;
      left: 40px;
      bottom: 20px;
    }
  }
  .loginLogo {
    border: none;
    outline: none;
    margin-right: auto;
    margin-left: auto;
    padding-top: 130px;
    width: 480px;
    // width: 31vw;
    // height: 91vh;
    // max-width: 31vw;
    // max-height: 91vh;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    // image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

    .loginLogo-1 {
      width: 480px;
    }
    .loginLogo-2 {
      margin-top: 52px;
      width: 395px;
      box-shadow: 68px 48px 36px #4365857a;
      border-radius: 43px;
    }
  }
}
.detail-wrap {
  position: relative;
  padding: 60px 30px 30px 30px;
  height: 100%;
  background-color: rgb(240, 242, 245);
  .detail-icon-wrap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    .text-right-top {
      padding: 30px 30px 0 0;
      text-align: right;
      i {
        color: #000;
        margin-right: 10px;
        cursor: pointer;
        &:first-of-type {
          font-size: 18px;
          vertical-align: 3px;
        }
        &:last-of-type {
          font-size: 28px;
        }
      }
    }
  }
  .pic-des {
    width: 69%;
    margin: 0 auto;
    color: #000;
    text-align: left;
    margin-top: 20px;
    & > p {
      text-align: center;
    }
  }
  .abbreviation-list-wrap {
    width: calc(100% - 60px);
    margin: 0 auto;
    padding: 0 60px;
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    .abbreviation-list {
      display: flex;
      width: 100%;
      overflow: auto;
      color: #000;
      scroll-behavior: smooth;
      scrollbar-color: transparent transparent;
      .currentUpdate {
        border: 1px solid #174fa9;
      }
      img {
        &:hover {
          cursor: pointer;
        }
      }
      & > div {
        margin: 0 10px;
        & > p {
          margin-top: 0px;
        }
      }
    }
  }
}
.shadowBox {
  position: relative;
  z-index: 1;
}
.shadowBox::after {
  content: " ";
  position: absolute;
  right: 30px;
  bottom: -6px;
  background-color: #436585;
  box-shadow: 16px 12px 20px #436585;
  width: 392px;
  height: 405px;
  z-index: -1;
  border-radius: 59px;
}
</style>
<style lang="scss">
.custom_update_class.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body {
    padding: 0px !important;
    height: 100%;
    overflow: hidden;
  }
  .el-carousel__container {
    height: 600px;
  }
}
</style>
